.translate-button {
  display: inline-block;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background-color .25s ease-out,color .25s ease-out;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: .85em 1em;
  margin: 1rem 0 1rem;
  font-size: .9rem;
  background-color: #ed6c08;
  color: #fefefe;
  text-transform: uppercase; }

.translate-button:hover {
  background-color: #c95c07;
  color: #fefefe; }
.slide {
  margin-top: 90px;
  background-size: 520px!important;
  background-position: 50% 70%!important; }
.lSSlideOuter .lSPager.lSpg {
  display: none; }
.lSAction>a {
  display: none; }
.mobile-phone {
  display: none; }

@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,700&subset=latin-ext');
body {
  font-family: 'Open Sans Condensed', sans-serif;
  background: no-repeat,linear-gradient(-90deg, #41caff, #8cebff); }
h3 {
  color: #ff8537; }
footer {
  background-color: white;

  h2,.p {
    color: black; } }
h2 {
  color: #ff8537;
  text-transform: inherit !important; }

a:hover {
  text-decoration: none; }
.navbar-default .navbar-nav>li>a, .navbar-default .navbar-text {
  font-size: 18px;
  font-weight: bold;
  color: black;
  border-bottom: 1px solid #f7f7f7; }
.top-info {
  float: right;
  margin-top: 40px;
  margin-right: 40px;
  position: absolute;
  top: 0;
  right: 0; }
.line {
  color: white; }

.header-phone {
  font-weight: bold;
  color: black;
  border-radius: 40px;
  padding: 10px;
  background-color: white;
  display: inline-block;
  margin-right: 15px;
  @include box-shadow(inset 0 -4px 0 #d9d9d9);
  &:hover {
    @include box-shadow(inset 0 0px 0 #d9d9d9);
    color: black; } }
.header-mail {
  font-weight: bold;
  color: black;
  border-radius: 40px;
  padding: 10px;
  background-color: white;
  display: inline-block;
  @include box-shadow(inset 0 -4px 0 #d9d9d9);
  &:hover {
    @include box-shadow(inset 0 0px 0 #d9d9d9);
    color: black; } }

.navbar-nav {
  width: 180px;
  background-color: white;
  li.last {
    a:last-child {
      border-bottom: none; } } }
.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
  color: #00aeef; }
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
  background-color: transparent;
  background-image: none;
  color: #00aeef;
 }  //box-shadow: none
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.open>a {
  background-color: transparent;
  background-image: none;
  color: #00aeef;
  box-shadow: none; }

.navbar-default {
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  border: none;
  position: fixed;
  left: 40px;
  top: 100px;
  z-index: 601; }
.navbar-brand {
  height: auto; }

.logo-bg {
  background-image: url(../images/logo-bg.svg);
  background-repeat: no-repeat;
  height: 143px;
  float: left;
  z-index: 631;
  background-size: 100%;
  width: 320px;
  position: fixed; }

.navbar-brand>img {
  height: 60px;
  margin-left: 20px;
  margin-top: 5px; }
.navbar-nav>li {
  float: none; }
.navbar-nav {
  border-radius: 10px;
  z-index: 204;
  position: relative;
  padding-top: 50px; }
.skola-1,.skola-2,.skola-3 {
  background-color: #393232;
  border: 3px solid white;
  border-radius: 10px;
  padding-left: 100px;
  height: 140px;
  p {
    color: white;
    margin-top: 42px;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0; }
  span {
    color: #fcb040;
    font-size: 16px;
    font-weight: bold; }
  &:hover {
    background-color: #292424; } }

.skola-1 {
  background-image: url("../images/icon-jedalny-listok.svg");
  background-repeat: no-repeat;
  background-position: 20px;
  background-size: 64px;
  display: block;

  span {} }

.skola-2 {
  background-image: url("../images/icon-tlaciva.svg");
  background-repeat: no-repeat;
  background-position: 20px;
  background-size: 64px;
  display: block;
  span {} }

.skola-3 {
  background-image: url("../images/icon-galeria.svg");
  background-repeat: no-repeat;
  background-position: 20px;
  background-size: 64px;
  display: block;
  span {} }


header {
  height: 600px; }
.top-home {
  margin-top: -180px; }
.new {
  text-align: center;
  margin-top: 80px;
  color: #ff8537;
  font-weight: bold;
  font-size: 40px;
  text-shadow: rgb(255, 255, 255) 7px 0px 0px, rgb(255, 255, 255) 6.92869px 0.996602px 0px, rgb(255, 255, 255) 6.71622px 1.9729px 0px, rgb(255, 255, 255) 6.36692px 2.909px 0px, rgb(255, 255, 255) 5.8879px 3.78584px 0px, rgb(255, 255, 255) 5.28893px 4.58555px 0px, rgb(255, 255, 255) 4.5822px 5.29183px 0px, rgb(255, 255, 255) 3.78212px 5.8903px 0px, rgb(255, 255, 255) 2.90498px 6.36876px 0px, rgb(255, 255, 255) 1.96865px 6.71747px 0px, rgb(255, 255, 255) 0.992221px 6.92932px 0px, rgb(255, 255, 255) -0.00442571px 7px 0px, rgb(255, 255, 255) -1.00098px 6.92806px 0px, rgb(255, 255, 255) -1.97715px 6.71498px 0px, rgb(255, 255, 255) -2.91303px 6.36508px 0px, rgb(255, 255, 255) -3.78956px 5.88551px 0px, rgb(255, 255, 255) -4.58889px 5.28603px 0px, rgb(255, 255, 255) -5.29472px 4.57885px 0px, rgb(255, 255, 255) -5.89269px 3.77839px 0px, rgb(255, 255, 255) -6.3706px 2.90095px 0px, rgb(255, 255, 255) -6.71871px 1.96441px 0px, rgb(255, 255, 255) -6.92995px 0.98784px 0px, rgb(255, 255, 255) -6.99999px -0.00885142px 0px, rgb(255, 255, 255) -6.92743px -1.00536px 0px, rgb(255, 255, 255) -6.71372px -1.98139px 0px, rgb(255, 255, 255) -6.36324px -2.91705px 0px, rgb(255, 255, 255) -5.88311px -3.79328px 0px, rgb(255, 255, 255) -5.28313px -4.59223px 0px, rgb(255, 255, 255) -4.57551px -5.29762px 0px, rgb(255, 255, 255) -3.77466px -5.89507px 0px, rgb(255, 255, 255) -2.89692px -6.37243px 0px, rgb(255, 255, 255) -1.96016px -6.71995px 0px, rgb(255, 255, 255) -0.983458px -6.93057px 0px, rgb(255, 255, 255) 0.0132771px -6.99999px 0px, rgb(255, 255, 255) 1.00974px -6.92679px 0px, rgb(255, 255, 255) 1.98564px -6.71247px 0px, rgb(255, 255, 255) 2.92107px -6.36139px 0px, rgb(255, 255, 255) 3.797px -5.88071px 0px, rgb(255, 255, 255) 4.59557px -5.28022px 0px, rgb(255, 255, 255) 5.30051px -4.57215px 0px, rgb(255, 255, 255) 5.89746px -3.77094px 0px, rgb(255, 255, 255) 6.37426px -2.89289px 0px, rgb(255, 255, 255) 6.72119px -1.95591px 0px, rgb(255, 255, 255) 6.93119px -0.979076px 0px;
  margin-bottom: 60px;
  font-family: 'Roboto Slab', serif; }
.border-top {
  .obal {
    color: #fff;
    background-color: #ff8537;
    border-radius: 110px;
    width: 130px;
    margin: 0 auto;
    text-align: center;
    padding: 15px;
    padding-left: 50px;
    padding-right: 50px;
    font-weight: bold;
    position: absolute;
    left: 0;
    right: 0;
    top: -40px;
    font-size: 14px; } }
.obal {
  width: 90px;
  margin: 0 auto;
  background-color: #ff8537;
  border-radius: 80px;
  height: 90px;
  position: absolute;
  left: 0;
  right: 0;
  top: -35px;
  span {
    display: block;
    color: white;
    text-align: center;
    line-height: 20px;
    font-family: 'Roboto Slab', serif; } }
.den {
  margin-top: 15px;
  font-size: 22px;
  font-weight: bold; }
.mesiac,.rok {
  font-size: 14px; }
.split {
  h4 {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    color: black;
    margin-top: 0;
    padding-top: 20px;
    min-height: 68px;
    &:hover {
      color: #00aeef; } }
  &:hover {
    color: #00aeef !important; } }
.regular {
  min-height: 40px;
  p {
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    color: black; } }
.art-photo {
  width: auto !important; }
.read {
  background-color: #ff8537;
  color: white !important;
  padding: 10px;
  border-radius: 30px;
  display: block !important;
  margin: 0 auto;
  width: 130px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  position: relative;
  @include box-shadow(inset 0 -4px 0 #d35200);
  &:hover {
    @include box-shadow(inset 0 0px 0 #d35200);
    color: white; } }
.content-web {
  .read {
    width: 150px !important; } }

.aktual-bg {
  background-color: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom: 4px solid #d3d3d3; }
.read-back {
  padding-bottom: 20px; }
.belt {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px; }
.light-grey {
  border-bottom: 1px solid #dddddd;
  width: 90%;
  margin: 0 auto;
  position: relative;
  top: 25px; }
.home {
  .luka {
    background-image: url("../images/bg-luka.png");
    background-repeat: no-repeat;
    position: absolute;
    height: 888px;
    width: 100%;
    background-size: cover; }
  footer {
    margin-top: 0 !important; } }

.ng-scope {
  footer {
    margin-top: -150px; } }
footer {
  .container {
    position: relative; } }
.luka-2 {
  background-image: url("../images/bg-luka.svg");
  background-repeat: no-repeat;
  position: relative;
  height: 888px;
  width: 100%;
  background-size: cover;
  margin-top: -500px; }
section.static-content {
  margin-top: -500px;
  position: relative;
  left: 0;
  right: 0;
  z-index: 523; }
.special {
  margin-top: -500px;
  position: relative;
  left: 0;
  right: 0;
  z-index: 599; }
.detail-page {
  margin-top: -500px;
  position: relative;
  left: 0;
  right: 0;
  z-index: 599; }
.kontakt-section {
  margin-top: -500px;
  position: relative;
  left: 0;
  right: 0;
  z-index: 599; }
.gallery-section {
  margin-top: -500px;
  position: relative;
  left: 0;
  right: 0;
  z-index: 599; }
.detail-gal-section {
  margin-top: -500px;
  position: relative;
  left: 0;
  right: 0;
  z-index: 599; }
.gal {
  .castle {
    border-radius: 15px; }
  a {
    display: block; } }
.gal {
  margin-top: 55px;
  .obal {
    top: 10px;
    background-image: url(../images/icon-camera.svg);
    background-position: center;
    background-size: 35px;
    background-repeat: no-repeat; } }
.aktualita {
  margin-top: 55px;
  .obal {
    top: 10px; } }
.home {
  .aktualita {
    margin-top: 0px;
    .obal {
      top: -35px; } } }

.gal-back {
  padding: 40px;
  background-color: #fff;
  min-height: 700px;
  border-radius: 10px;
  float: left;
  width: 100%; }
.gal-name {
  font-size: 40px;
  color: #fff;
  font-weight: 700;
  padding-left: 30px;
  font-weight: bold;
  color: #ff8537;
  text-shadow: rgb(255, 255, 255) 7px 0px 0px, rgb(255, 255, 255) 6.92869px 0.996602px 0px, rgb(255, 255, 255) 6.71622px 1.9729px 0px, rgb(255, 255, 255) 6.36692px 2.909px 0px, rgb(255, 255, 255) 5.8879px 3.78584px 0px, rgb(255, 255, 255) 5.28893px 4.58555px 0px, rgb(255, 255, 255) 4.5822px 5.29183px 0px, rgb(255, 255, 255) 3.78212px 5.8903px 0px, rgb(255, 255, 255) 2.90498px 6.36876px 0px, rgb(255, 255, 255) 1.96865px 6.71747px 0px, rgb(255, 255, 255) 0.992221px 6.92932px 0px, rgb(255, 255, 255) -0.00442571px 7px 0px, rgb(255, 255, 255) -1.00098px 6.92806px 0px, rgb(255, 255, 255) -1.97715px 6.71498px 0px, rgb(255, 255, 255) -2.91303px 6.36508px 0px, rgb(255, 255, 255) -3.78956px 5.88551px 0px, rgb(255, 255, 255) -4.58889px 5.28603px 0px, rgb(255, 255, 255) -5.29472px 4.57885px 0px, rgb(255, 255, 255) -5.89269px 3.77839px 0px, rgb(255, 255, 255) -6.3706px 2.90095px 0px, rgb(255, 255, 255) -6.71871px 1.96441px 0px, rgb(255, 255, 255) -6.92995px 0.98784px 0px, rgb(255, 255, 255) -6.99999px -0.00885142px 0px, rgb(255, 255, 255) -6.92743px -1.00536px 0px, rgb(255, 255, 255) -6.71372px -1.98139px 0px, rgb(255, 255, 255) -6.36324px -2.91705px 0px, rgb(255, 255, 255) -5.88311px -3.79328px 0px, rgb(255, 255, 255) -5.28313px -4.59223px 0px, rgb(255, 255, 255) -4.57551px -5.29762px 0px, rgb(255, 255, 255) -3.77466px -5.89507px 0px, rgb(255, 255, 255) -2.89692px -6.37243px 0px, rgb(255, 255, 255) -1.96016px -6.71995px 0px, rgb(255, 255, 255) -0.983458px -6.93057px 0px, rgb(255, 255, 255) 0.0132771px -6.99999px 0px, rgb(255, 255, 255) 1.00974px -6.92679px 0px, rgb(255, 255, 255) 1.98564px -6.71247px 0px, rgb(255, 255, 255) 2.92107px -6.36139px 0px, rgb(255, 255, 255) 3.797px -5.88071px 0px, rgb(255, 255, 255) 4.59557px -5.28022px 0px, rgb(255, 255, 255) 5.30051px -4.57215px 0px, rgb(255, 255, 255) 5.89746px -3.77094px 0px, rgb(255, 255, 255) 6.37426px -2.89289px 0px, rgb(255, 255, 255) 6.72119px -1.95591px 0px, rgb(255, 255, 255) 6.93119px -0.979076px 0px;
  font-family: 'Roboto Slab', serif; }
.detail-article {
  padding: 40px;
  font-size: 16px;
  font-family: 'Open Sans Condensed',sans-serif;
  background-color: #fff;
  min-height: 700px;
  border-radius: 10px; }
.kontakt-page {
  padding: 40px;
  font-size: 16px;
  font-family: 'Open Sans',sans-serif;
  background-color: #fff;
  min-height: 1000px;
  border-radius: 10px;
  line-height: 25px;
  h2 {
    text-align: left; }
  &:first-child h2 {
    margin-top: 0; } }
.photo {
  width: 800px;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 15px;
  img {} }

.clear {
  clear: both; }
.gal-detail-2 {
  height: 150px;
  margin-bottom: 30px; }
.main-bar {
  h1 {
    font-size: 40px;
    color: white;
    font-weight: bold;
    color: #ff8537;
    text-shadow: rgb(255, 255, 255) 7px 0px 0px, rgb(255, 255, 255) 6.92869px 0.996602px 0px, rgb(255, 255, 255) 6.71622px 1.9729px 0px, rgb(255, 255, 255) 6.36692px 2.909px 0px, rgb(255, 255, 255) 5.8879px 3.78584px 0px, rgb(255, 255, 255) 5.28893px 4.58555px 0px, rgb(255, 255, 255) 4.5822px 5.29183px 0px, rgb(255, 255, 255) 3.78212px 5.8903px 0px, rgb(255, 255, 255) 2.90498px 6.36876px 0px, rgb(255, 255, 255) 1.96865px 6.71747px 0px, rgb(255, 255, 255) 0.992221px 6.92932px 0px, rgb(255, 255, 255) -0.00442571px 7px 0px, rgb(255, 255, 255) -1.00098px 6.92806px 0px, rgb(255, 255, 255) -1.97715px 6.71498px 0px, rgb(255, 255, 255) -2.91303px 6.36508px 0px, rgb(255, 255, 255) -3.78956px 5.88551px 0px, rgb(255, 255, 255) -4.58889px 5.28603px 0px, rgb(255, 255, 255) -5.29472px 4.57885px 0px, rgb(255, 255, 255) -5.89269px 3.77839px 0px, rgb(255, 255, 255) -6.3706px 2.90095px 0px, rgb(255, 255, 255) -6.71871px 1.96441px 0px, rgb(255, 255, 255) -6.92995px 0.98784px 0px, rgb(255, 255, 255) -6.99999px -0.00885142px 0px, rgb(255, 255, 255) -6.92743px -1.00536px 0px, rgb(255, 255, 255) -6.71372px -1.98139px 0px, rgb(255, 255, 255) -6.36324px -2.91705px 0px, rgb(255, 255, 255) -5.88311px -3.79328px 0px, rgb(255, 255, 255) -5.28313px -4.59223px 0px, rgb(255, 255, 255) -4.57551px -5.29762px 0px, rgb(255, 255, 255) -3.77466px -5.89507px 0px, rgb(255, 255, 255) -2.89692px -6.37243px 0px, rgb(255, 255, 255) -1.96016px -6.71995px 0px, rgb(255, 255, 255) -0.983458px -6.93057px 0px, rgb(255, 255, 255) 0.0132771px -6.99999px 0px, rgb(255, 255, 255) 1.00974px -6.92679px 0px, rgb(255, 255, 255) 1.98564px -6.71247px 0px, rgb(255, 255, 255) 2.92107px -6.36139px 0px, rgb(255, 255, 255) 3.797px -5.88071px 0px, rgb(255, 255, 255) 4.59557px -5.28022px 0px, rgb(255, 255, 255) 5.30051px -4.57215px 0px, rgb(255, 255, 255) 5.89746px -3.77094px 0px, rgb(255, 255, 255) 6.37426px -2.89289px 0px, rgb(255, 255, 255) 6.72119px -1.95591px 0px, rgb(255, 255, 255) 6.93119px -0.979076px 0px;
    font-family: 'Roboto Slab', serif; } }
.obsah {
  position: relative; }
.all-news {
  margin: 30px auto;
  a {
    display: block;
    width: 210px;
    text-align: center;
    margin: 0 auto;
    padding: 10px;
    border-radius: 30px;
    background-color: #ff8537;
    color: white;
    font-size: 18px;
    font-weight: bold;
    @include box-shadow(inset 0 -4px 0 #d35200);
    &:hover {
      @include box-shadow(inset 0 0px 0 #d35200);
      color: white; } } }
.home-more {
  background-image: url("../images/bg-puzzle.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  margin-top: 60px;
  padding-top: 30px;
  p {
    color: #ff2e6c;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    margin: 40px auto;
    font-family: 'Roboto Slab', serif; }
  a {
    font-size: 25px;
    font-weight: bold;
    background-color: #00a651;
    color: white;
    padding: 10px;
    width: 290px;
    display: block;
    margin: 0 auto;
    text-align: center;
    @include box-shadow(inset 0 -4px 0 #004a24);
    border-radius: 30px;
    &:hover {
      @include box-shadow(inset 0 0px 0 #004a24); } } }
.special-2 {
  color: #ff2e6c !important;
  font-size: 40px !important;
  font-weight: bold !important;
  text-align: center !important;
  margin: 40px auto !important;
  font-family: 'Roboto Slab', serif !important;
  background-color: transparent !important;
  width: auto !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  &:hover {
    box-shadow: none !important;
    color: #00aeef !important; } }

.fb-line {
  border-bottom: 1px solid #d7d7d7;
  margin-top: 70px; }
.fb {
  height: 40px; }
.fb-box {
  width: 100px;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  text-align: center;
  background-color: white; }
.footer-h2 {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  text-transform: capitalize; }
.footer-a {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  display: block;
  color: black;
  &:hover {
    color: #00aeef; } }
.footer-p {
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 18px; }
.footer-phone {
  font-weight: bold;
  color: white;
  border-radius: 40px;
  padding: 10px;
  background-color: #ff8537;
  display: block;
  width: 130px;
  margin: 30px auto;
  text-align: center;
  @include box-shadow(inset 0 -4px 0 #d35200);
  &:hover {
    @include box-shadow(inset 0 0px 0 #d35200);
    color: white; } }
.footer-mail {
  font-weight: bold;
  color: white;
  border-radius: 40px;
  padding: 10px;
  background-color: #ff8537;
  display: block;
  width: 200px;
  margin: 10px auto;
  text-align: center;
  @include box-shadow(inset 0 -4px 0 #d35200);
  &:hover {
    @include box-shadow(inset 0 0px 0 #d35200);
    color: white; } }

.btn {
  font-weight: bold;
  color: white;
  border-radius: 40px;
  padding: 10px;
  background-color: #ff8537;
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  @include box-shadow(inset 0 -4px 0 #d35200);
  &:hover {
    @include box-shadow(inset 0 0px 0 #d35200);
    color: white; } }
.btn-back {
  font-weight: bold;
  color: white;
  border-radius: 40px;
  padding: 10px;
  background-color: #ff8537;
  display: block;
  width: 100px;
  text-align: center;
  @include box-shadow(inset 0 -4px 0 #d35200);
  margin-top: 30px;
  &:hover {
    @include box-shadow(inset 0 0px 0 #d35200);
    color: white; } }
.list-art {
  font-weight: bold;
  color: white;
  border-radius: 40px;
  padding: 10px;
  background-color: #ff8537;
  display: block;
  width: 100px;
  text-align: center;
  @include box-shadow(inset 0 -4px 0 #d35200);
  margin-top: 30px;
  &:hover {
    @include box-shadow(inset 0 0px 0 #d35200);
    color: white; } }
.next-kontakt {
  color: #ff8537;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  display: block;
  margin: 30px auto;
  &:hover {
    color: #00aeef; } }
.find {
  iframe {
    border-radius: 10px; } }
.author {
  text-align: center;
  background-color: #00aeef;
  padding: 13px;
  font-size: 18px;
  font-weight: bold;
  a {
    color: white; }
  p {
    color: white; } }
.copy {
  &:hover {
    color: #ff8537; } }
.natural {
  &:hover {
    color: #ff8537; } }
.middle-2 {
  span {
    &:hover {
      color: #ff8537; } } }
.copy {
  font-size: 18px;
  font-weight: bold; }
.photo {
  img {
    height: 500px; } }

.menu-button {
  display: block; }
.input-menu {
  z-index: 299;
  height: 75px;
  position: absolute;
  top: 558px;
  left: 130px; }
//@media (max-width: 1199px) zapnut pri vhodnom rozliseni
.navbar-collapse.collapse {
  display: block !important; }
.navbar-collapse.collapse.in {
  display: none !important;
  position: absolute;
  z-index: 19;
  width: 90%;
  margin: 0 auto;
  left: 0;
  right: 0; }
.navbar-collapse.collapsing {
  display: none !important;
  position: absolute;
  z-index: 19;
  width: 90%;
  margin: 0 auto;
  left: 0;
  right: 0; }
.navbar-header .collapse, .navbar-toggle {
  display: block !important; }
.navbar-header {
  float: none; }
.navbar-default .navbar-toggle {
  background-color: white !important;
  border-radius: 50px;
  z-index: 5999;
  float: none;
  margin: 0 auto;
  position: absolute;
  left: 80px;
  margin-top: 40px;
  padding-top: 12px;
  padding-bottom: 12px; }
.menu-line {
  width: 50px;
  transform: rotate(90deg);
  border: 1px solid white;
  position: absolute;
  left: 77px; }
.navbar-default .navbar-toggle .icon-bar {
  background-color: black;
  height: 3px; }
.navbar-default .navbar-toggle .icon-bar:nth-child(2) {
  width: 15px;
  margin: 0 auto; }
.navbar-default .navbar-toggle .icon-bar:last-child {
  width: 15px;
  margin: 0 auto;
  margin-top: 5px; }
.responz-mobile {
  display: none; }
.gal-detail {
  .castle {
    height: 124px;
    width: 100%;
    margin-bottom: 30px; } }
.det-gal {
  margin-bottom: 30px; }
.gal-img {
  a {
    color: white;
    font-size: 18px;
    line-height: 25px;
    font-weight: bold;
    margin-top: 15px;
    display: block;
    font-family: 'Open Sans', sans-serif;
    position: relative;
    bottom: 120px;
    padding: 10px;
    background-color: rgba(0,0,0,.6);
    &:hover {
      background-color: rgba(0,0,0,1);
      color: #ff8537; } }
  p {
    color: white;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    line-height: 25px;
    bottom: 40px;
    position: relative;
    padding-left: 10px;
    text-align: justify; } }
.btn-setup {
  margin-left: 65px;
  margin-top: 15px;
  color: #ff8537;
  font-weight: bold;
  font-size: 18px; }
@media (max-width: 1630px) {
  .special {
    margin-top: -430px; }
  .detail-page {
    margin-top: -430px; }
  section.static-content {
    margin-top: -430px; }
  .gallery-section {
    margin-top: -430px; }
  .detail-gal-section {
    margin-top: -430px; }
  .kontakt-section {
    margin-top: -430px; }
  .navbar-collapse.collapse {
    display: none !important; }
  .navbar-collapse.collapse.in {
    display: block !important;
    position: absolute;
    z-index: 19;
    width: 90%;
    margin: 0 auto;
    left: 0;
    right: 0; }
  .navbar-collapse {
    padding-left: 0;
    padding-right: 0; }

  .navbar-collapse.collapsing {
    display: block !important;
    position: absolute;
    z-index: 19;
    width: 90%;
    margin: 0 auto;
    left: 0;
    right: 0; }
  .navbar-default .navbar-toggle.collapsed {
    background-color: #fff!important;
    border-radius: 50px;
    z-index: 5999;
    float: none;
    margin: 0 auto;
    position: absolute;
    left: 70px;
    margin-top: 60px;
    padding-top: 12px;
    padding-bottom: 12px;
    top: 0; }
  .navbar-default .navbar-toggle {
    top: 457px;
    left: 70px; }
  .menu-line {
    left: 67px;
    top: 14px; }
  .current {
    top: 457px; } }



@media (max-width: 1199px) {
  .skola-1,.skola-2,.skola-3 {
    background-size: 44px;
    padding-left: 70px;
    p {
      font-size: 22px; } }
  .home-more {
    margin-top: -35px; }
  .all-news {
    position: relative; } }
@media (max-width: 991px) {
  .skola-1, .skola-2, .skola-3 {
    background-position: 15px;
    p {
      margin-top: 32px;
      line-height: 24px; } }
  .home-more {
    margin-top: -87px; } }
@media (max-width: 767px) {
  #lightSlider {
    display: none; }
  .mobile-phone {
    display: block; }
  .desktop-phone {
    display: none; }
  //.top-home
  //margin-top: -100px
  .home .aktualita .obal {
    top: 10px; }
  .home .aktualita {
    margin-top: 50px; }
  .aktualita {
    margin-top: 50px; }
  .obal {
    top: 5px; }
  .home .luka {
    height: 1544px; }
  .photo {
    width: 100%;
    margin-top: 70px;
    img {
      height: auto; } }
  .white-line {
    width: 100%;
    height: 75px;
    background-color: white; }
  .logo-bg {
    height: auto;
    background-image: none; }
  .navbar-brand>img {
    height: 45px;
    margin-left: 10px;
    margin-top: 0; }
  .menu-line {
    display: none; }
  .responz-mobile {
    display: block;
    width: 45px;
    height: 45px;
    background-color: #fff!important;
    border-radius: 50px;
    padding-top: 12px;
    padding-bottom: 12px;
    float: right;
    z-index: 6999;
    position: relative;
    border: 1px solid #dddddd;
    margin-top: 15px;
    margin-right: 15px;
    .icon-bar {
      background-color: #000;
      height: 3px;
      width: 15px;
      margin: 0 auto;
      display: block; }
    .icon-bar:nth-child(2) {
      width: 15px;
      margin: 0 auto;
      margin-bottom: 5px; }
    .icon-bar:last-child {
      width: 15px;
      margin: 0 auto;
      margin-top: 5px; }
    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      clip: rect(0,0,0,0);
      border: 0; }
    &:focus {
      border-color: transparent;
      outline: -webkit-focus-ring-color auto 0px;
      border: 1px solid #ddd; } }
  .navbar-default {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto; }
  .navbar-nav {
    width: auto; }
  .navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: transparent; }
  .navbar-toggle {
    display: none !important; }
  .top-info {
    float: right;
    margin-top: 20px;
    margin-right: 75px;
    position: absolute;
    top: 0;
    right: 0; }
  .navbar-collapse.collapse {
    display: none !important; }
  .navbar-collapse.collapse.in {
    display: block !important;
    width: 100%;
    margin-top: -42px; }
  .navbar-nav>li>a {
    padding-top: 13px;
    padding-bottom: 13px; }
  .navbar-nav {
    padding-top: 0; }
  .header-phone {
    border: 1px solid #ddd; }
  .header-mail {
    border: 1px solid #ddd; }
  .logo-bg {
    position: absolute; }
  .special {
    margin-top: -500px; }
  .detail-page {
    margin-top: -500px; }
  section.static-content {
    margin-top: -500px; }
  .gallery-section {
    margin-top: -500px; }
  .detail-gal-section {
    margin-top: -500px; }
  .kontakt-section {
    margin-top: -500px; }
  .gal-name {
    padding-left: 15px; } }
@media (max-width: 548px) {
  .home .luka {
    height: 1568px; }
  .white-line {
    height: 135px; }
  .photo {
    //display: none
    margin-top: 130px; }
  .top-info {
    float: none;
    position: absolute;
    width: 296px;
    margin: 0 auto;
    top: 80px;
    right: 10px; }
  .navbar-nav {
    margin-top: 25px; }
  .navbar-collapse.collapse.in {
    margin-top: 0; }
  .special {
    margin-top: -440px; }
  .detail-page {
    margin-top: -440px; }
  section.static-content {
    margin-top: -440px; }
  .gallery-section {
    margin-top: -440px; }
  .detail-gal-section {
    margin-top: -440px; }
  .kontakt-section {
    margin-top: -440px; } }
@media (max-width: 531px) {
  .home .luka {
    height: 1592px; } }
@media (max-width: 387px) {
  .home .luka {
    height: 1652px; } }
